import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { IonicModule } from '@ionic/angular';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyFieldSelect, FormlyIonicModule } from '@ngx-formly/ionic';
import { TableModule } from 'ngx-easy-table';
import { NotfoundComponent } from './notfound/notfound.component';

import { NewChampPage, VvFormEditorComponent } from './vv-form-editor/vv-form-editor.component';
import { VvGroupFieldComponent } from './vv-group-field/vv-group-field.component';
import { VvTableComponent } from './vv-table/vv-table.component';




@NgModule({
    imports: [ 
        CommonModule,
        TableModule,
        FormsModule,
         IonicModule,
          DragDropModule,
          ReactiveFormsModule,
          FormlyIonicModule,
        FormlyModule.forRoot({
            types: [
                {
                  name: 'select',
                  component: FormlyFieldSelect,
                  wrappers: ['form-field'],
                },
                { name: 'enum', extends: 'select' },
              ],
            extras: { lazyRender: true }
          }),],
    declarations: [VvFormEditorComponent,VvGroupFieldComponent,NewChampPage,VvTableComponent,NotfoundComponent],
    exports: [VvFormEditorComponent,VvGroupFieldComponent,NewChampPage,VvTableComponent,NotfoundComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class ComponentsModule{}