import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyIonicModule } from '@ngx-formly/ionic';
import { HttpClientModule } from '@angular/common/http';
import { AuthGuard } from './guards/auth.guard';
import { AuthService } from './services/auth.service';
import { VvService } from './services/vv.service';
import { IonicStorageModule } from '@ionic/storage';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { VvFormEditorComponent } from './components/vv-form-editor/vv-form-editor.component';
import { VvGroupFieldComponent } from './components/vv-group-field/vv-group-field.component';
import { ComponentsModule } from './components/components.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { TableModule } from 'ngx-easy-table';
import { VvTableComponent } from './components/vv-table/vv-table.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    ComponentsModule,
    HttpClientModule,
    TableModule,
    NgxChartsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    ReactiveFormsModule,
    IonicStorageModule.forRoot(),
    DragDropModule,
    FormlyIonicModule,
    FormlyModule.forRoot({ 
          types: [
            { name: 'vvFormEditor', component: VvFormEditorComponent},
            { name: 'group' , component: VvGroupFieldComponent}
          ],
          extras: { lazyRender: true } 
        }
    ),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    StatusBar,
    SplashScreen,
    AuthGuard,
    AuthService,
    VvService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
