import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  /*{
    path: '',
    redirectTo: 'charte',
    pathMatch: 'full'
  },
  {
    path: 'eco-responsable',
    loadChildren: () => import('./pages/charte/charte.module').then( m => m.ChartePageModule),
  },
  ,
  {
    path: 'dsi',
    loadChildren: () => import('./pages/chartedsi/chartedsi.module').then( m => m.ChartedsiPageModule)
  },*/
 
  {

    path: 'admin',
    loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: ':id',
    loadChildren: () => import('./pages/charte/charte.module').then( m => m.ChartePageModule)
  },
  {
    path: '', component: NotfoundComponent
  },
  {
    path: 'charte-settings',
    loadChildren: () => import('./modal/charte-settings/charte-settings.module').then( m => m.CharteSettingsPageModule)
  },
  {
    path: 'aide',
    loadChildren: () => import('./modal/aide/aide.module').then( m => m.AidePageModule)
  },
  //{path: '**', redirectTo: '/404'},
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
