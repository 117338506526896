import { Component, OnInit } from '@angular/core';

import { MenuController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ActivatedRoute } from '@angular/router';
import { VvService } from './services/vv.service';
import { from } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  user: any;
  chartePages: any[];
  userChartes: any[];
 

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private menuController: MenuController,
    public vvservice:VvService,
    private activeRoute:ActivatedRoute
  ) {
    this.initializeApp();
    this.menuController.close('main-content');
    this.menuController.enable(false);
    
    
    

    
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  async ngOnInit() {
    

    const path = window.location.pathname;
    console.log("Chemin :",path)

    let auth = await this.vvservice.feathers.get('authentication');
    

    if (auth) {
      this.user = auth.user;
      //this.userChartes = this.vvservice.chartes.filter(elt => this.user.allowChartes.includes(elt));

      console.log("App Auth user",this.user)
      //this.chartePages = this.vvservice.activesChartes;
      if (this.user.role =='admin') {
        this.chartePages = this.vvservice.chartes;
      } else {
        this.chartePages = this.vvservice.chartes.filter(elt => this.user.allowChartes.includes(elt));
      }
      

    } else {
      this.menuController.close('main-content');
      this.menuController.enable(false);
    }

    let getChartes = await this.vvservice.dynService_find('mongodb','chartes',JSON.stringify({}));

    // si la table chartes n'existe pas on creer nos deux chartes initiales
    if (getChartes.length==0) {
      console.log("INITIALISATION  des chartes de base")
      await this.vvservice.dynService_create('mongodb','chartes',{
        label: 'CHARTE ECO-RESPONSABLE',
        nom: 'charteco',
        path: 'eco-responsable',
        fnom: 'Charte 2020_v2.pdf',
        msujet: 'Merci de votre engagement',
        mtitle: 'Félicitations ! ',
        mcontent: `Félicitations,<br> 
        <b>Vous êtes désormais un agent écoresponsable et je vous remercie pour votre engagement.</b><br>
        Fred VIELET,<br>
        Directeur Général des Services 
        `
      })
      await this.vvservice.dynService_create('mongodb','chartes',{
        label: 'CHARTE DSI',
        nom: 'chartedsi',
        path: 'dsi',
        fnom: 'CHARTE INFORMATIQUE.pdf',
        msujet: 'Merci de votre acceptation',
        mtitle: 'Félicitation!!',
        mcontent: '<br>dffdf'
    
      });
      this.vvservice.chartes = await this.vvservice.dynService_find('mongodb','chartes',JSON.stringify({}));
    } else {
      this.vvservice.chartes = getChartes;
    }
    
    /*.split('folder/')[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }*/
  }
  
  
  changeSegment(nom) {
    this.vvservice.activeSegment.next(nom);
  }


}
