import { ChangeDetectorRef, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { API, APIDefinition, Columns, Config, DefaultConfig, Pagination } from 'ngx-easy-table';
import { Observable } from 'rxjs';
import * as XLSX from 'xlsx';
import { jsPDF } from 'jspdf'
import 'jspdf-autotable';
import { VvService } from 'src/app/services/vv.service';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { map } from 'rxjs/operators';

type AOA = any[][];


@Component({
  selector: 'vv-table',
  templateUrl: './vv-table.component.html',
  styleUrls: ['./vv-table.component.scss'],
  providers: [
    VvService // <-- List providers here
  ]
})
export class VvTableComponent implements OnInit {



  @Input() dbType:string;
  @Input() dbName:string;

  @Input() vvservice:VvService;

  @ViewChild('table', { static: true }) table: APIDefinition;

  @ViewChild('actionTpl', { static: true }) actionTpl: TemplateRef<any>;

  @ViewChild('titleHeaderActionTemplate', { static: true })
  titleHeaderActionTemplate: TemplateRef<any>;

  data: AOA = [[1, 2], [3, 4]];
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  fileName: string = 'SheetJS.xlsx';

  
  rdatas$: Observable<any[]>;
  rdatas: any[];
  colsDatas: any[];
  newColumns: Columns[] = [];
  memColumns: Columns[] = [];
  selectedCols: string[] = [];

  styleDatasColumns: any[];
  exportDatasColumns: { title: any; dataKey: any; }[];

  filterLength: any;

  exportVisiblesCols: any[] = [];


  public configuration: Config;
  public selected = new Set();
  isFilter: boolean;
  copyColumns: Columns[];
  selectedLines: any[];
  public toggledRows = new Set<number>();
  paginationTotalItems: number;
  pagination: Pagination;
  frdatas$: Observable<any[]>;
  selectedLine: any;
  loading: HTMLIonLoadingElement;



  constructor(
    //private vvservice:VvService,
    private cdr: ChangeDetectorRef,private modalController: ModalController,
    private loadingController: LoadingController,private alertController: AlertController) { 
    this.configuration = { ...DefaultConfig };
    //this.configuration.checkboxes = this.showDatabase;
    this.configuration.horizontalScroll = true;
    this.configuration.searchEnabled = false;
    this.configuration.headerEnabled = true;
    this.configuration.selectCol = true;
    this.configuration.additionalActions = true;
    this.configuration.tableLayout.borderless = false;
    this.configuration.tableLayout.striped = true;
    this.configuration.tableLayout.style = 'normal';
  }

  ngOnInit() {
    this.loadInitDatas()
  }

  ionViewdidEnter() {
    this.loadInitDatas()
  }

  async presentLoading() {
    this.loading = await this.loadingController.create({
      message: 'Chargement en cours...',
      //duration: 2000,
      spinner: 'bubbles'
    });
    this.loading.present();
  }


  async hideLoading() {
    if (this.loading) {
      this.loading.dismiss();
    }
  }

  loadInitDatas() {
    //console.log("init")
    this.configuration.isLoading = true;
    /*this.rdatas$ = this.vvservice.obsService_find$(this.dbType,this.dbName,JSON.stringify({})).pipe(
      map(*/ 
    this.vvservice.dynService_find(this.dbType,this.dbName,JSON.stringify({})).then((res:any) => {
        console.log("Datas :", res);

        if (res?.data && res?.limit) {
         // this.isSysDB = true;
          this.rdatas = res.data
        } else {
          this.rdatas = res;
        }


        if (this.rdatas && (this.rdatas.length > 0)) {
          console.log("Datas :", this.rdatas);
          this.configuration.isLoading = false;
          let arrKeys = Object.keys(this.rdatas[0]);
          let memCount = Object.keys(this.rdatas[0]).length;
          this.rdatas.forEach((element) =>{
            if (Object.keys(element).length > memCount) {
              memCount = Object.keys(element).length;
              arrKeys = Object.keys(element);
            }


          })
          console.log("Keys :",Object.keys(this.rdatas[0]));

          this.colsDatas = [];
          this.newColumns = [];
          this.selectedCols = [];
          //entete des données
          arrKeys.forEach((val, index, array) => {
            //colonnes selectionnées
            this.selectedCols.push(val);
            // memorise les entetes de colonne
            this.newColumns.push({ key: val, title: val });
            this.memColumns.push({ key: val, title: val });
          });

           this.newColumns.push({ key: 'action', title: 'Actions', cellTemplate: this.actionTpl })
           this.newColumns = this.newColumns.filter(x => (x.key!=='_id'))

          // donnees memorisées pour changer le style
          this.styleDatasColumns = [];
          this.styleDatasColumns = this.newColumns.map((col) => ({
            label: col.title,
            value: col.key,
            visible: true
          }));

          // données exportables
          this.exportDatasColumns = this.newColumns.map((col) =>({
            title: col.title,
            dataKey: col.key,
          }));



        }

        //return this.rdatas;
   })
   // )
    //this.frdatas$ = this.rdatas$;


  }

  changeTitle(key,value) {
    console.log("Col key :",key)
    console.log("New value :",value)
    this.newColumns[this.newColumns.findIndex(x => x.key == key)].title = value;
    this.exportDatasColumns[this.exportDatasColumns.findIndex(x => x.dataKey == key)].title = value;
    //this.exportDatasColumns[this.exportDatasColumns.findIndex(x => x.title == key)].dataKey = value;
  }

  toggleCol(event) {
    console.log("Checked :",event.detail.checked)
  }

  async action(rowIndex) {

    console.log("Supression de index:",rowIndex);
    console.log("Ligne :",this.rdatas[rowIndex]._id);
   if ( this.rdatas[rowIndex]._id) {
     const alert = await this.alertController.create({
        header: 'Confirmation de suppression!',
        message: 'Etes vous certain de  <strong>supprimer</strong> cette ligne !!!',
        buttons: [
          {
            text: 'Annuler',
            role: 'cancel',
            cssClass: 'ion-color-primary ion-button-solid',
            handler: () => {
              console.log('Confirm Cancel: blah');
            }
          }, {
            text: 'Supprimer',
            cssClass: 'ion-color-primary ion-button-solid',
            handler: () => {
              console.log('Confirm ok');
              this.vvservice.dynService_remove(this.rdatas[rowIndex]._id,this.dbType,this.dbName,null).then((suppData)=>{
                console.log("Donnée supprimée :",suppData)
                this.loadInitDatas()
              })
            }
          }
        ]
      });

      await alert.present();
   }


  }


  borderless(): void {
    this.configuration.tableLayout.borderless = !this.configuration.tableLayout.borderless;
    this.configuration = { ...this.configuration };
  }

  striped(): void {
    this.configuration.tableLayout.striped = !this.configuration.tableLayout.striped;
    this.configuration = { ...this.configuration };
  }


  setTheme(theme: string): void {
    this.configuration.tableLayout.theme = theme;
    this.configuration = { ...this.configuration };
  }

  hidesColumns() {
    console.log("Colones visibles :", this.selectedCols);
    console.log("Colones  :", this.memColumns);

    this.copyColumns = [];
    this.memColumns.forEach((columns)=>{
      this.selectedCols.forEach((val) => {
          if (columns.key == val) {
            this.copyColumns.push({key:columns.key, title:columns.title});

          }
      });

    });



    console.log("Colones copies : ",this.copyColumns);

    this.newColumns = this.copyColumns;


    console.log("Colones styles :", this.styleDatasColumns);
    console.log("Colones selectionnées :", this.selectedCols);

    this.exportVisiblesCols = [];
    this.copyColumns.forEach((val, index, arr) => {
      //console.log("Val row colone :", val);

        this.exportVisiblesCols.push({
          title: val.title,
          dataKey: val.key,
        });



    });

    console.log("Données exportables :", this.exportVisiblesCols);

  }


  async eventEmitted($event: { event: string; value: any }) {
    console.log("Selection :",$event)
    switch ($event.event) {
      case 'onSearch':
        if (($event?.value.length>0) && ($event.value[0].value!='')) {
          this.isFilter = true;
          let filerResult = this.rdatas.filter(x => x[$event.value[0].key] == $event.value[0].value );
          if ( filerResult ) {
            this.filterLength = filerResult.length;
          }
          console.log("Filter values :",filerResult);
        } else {
          this.isFilter = false;
        }


        break;
      case 'onClick':
        console.log("Edition d'une ligne")
        this.selectedLine = $event.value?.row;
        /*const modal = await this.modalController.create({
          component: DbformPage,
          backdropDismiss: false,
          //cssClass: 'my-custom-class'
          componentProps: {
            'dbName': this.dbTable,
            'dbType': this.dbType,
            'mode':'edit',
            'model':$event.value?.row,
            'visibleFields':this.selectedCols,
            'filename':this.filename.getValue()
          }
        });
        await modal.present();
        const { data } = await modal.onWillDismiss();*/
        //this.loadInitDatas();
        break;
      case 'onCheckboxSelect':
        console.log("Row select :",$event.value.row)
        if (this.selected.has($event.value.rowId)) {
          this.selected.delete($event.value.rowId);
        } else {
          this.selected.add($event.value.rowId);
        }
        console.log("Selected :",this.selected);
        break;
      case 'onSelectAll':
        this.rdatas.forEach((_, key) => {
          if (this.selected.has(key)) {
            this.selected.delete(key);
          } else {
            this.selected.add(key);
          }
        });
        this.selectedLines = this.rdatas;
        console.log("Lignes selectionées :",this.selectedLines);
        console.log("Data selected :",this.rdatas);

        break;
    }
  }




  searchGlobal(name: string): void {
    this.table.apiEvent({
      type: API.onGlobalSearch,
      value: name,
    });
  }

  public enableSearch(): void {
    this.configuration.searchEnabled = !this.configuration.searchEnabled;
    this.isFilter = this.configuration.searchEnabled;
  }



  exportDatasPdf() {

    import("jspdf-autotable").then((x) => {
      const doc = new jsPDF();
      let columns;
      let body;
      if (this.exportVisiblesCols.length > 0) {
        const expDatas: any[] = [];
        this.rdatas.forEach((elt, elti, eltarr) => {
          let obj: any = {};
          this.exportVisiblesCols.forEach((val, index, arr) => {
            obj[val.title] = elt[val.title]
          });
          //console.log("données exportables:",obj);
          expDatas.push(obj);
        });

        columns = this.exportVisiblesCols;//this.exportDatasColumns;
        //body = expDatas;
        let i=0;
        let newdatas = expDatas.map(x => ({  numero:i++, ...x}));
        columns.reverse().push({
          title: 'numero',
          dataKey: 'numero',
        })
        body = newdatas;
      } else {
        columns = this.exportDatasColumns;

        console.log("Columns :",columns)
        let newexp = []
        this.exportDatasColumns.forEach((col) => {
          let obj = {
            title: col.title,
            dataKey: col.dataKey
          }
          if (col.title!='Actions') {
            newexp.push(obj)
          }
        })
        console.log("Columns :",newexp)
        columns = newexp;
        /*let i=0;
        let newdatas = this.rdatas.map(x => ({ numero:i++, ...x}));
        columns.reverse().push({
          title: 'numero',
          dataKey: 'numero',
        })*/

        
        let newdatas = this.rdatas.map(x => { 
          delete x._id
          return x 
        })
        body = newdatas;//;
      }
      //
      //console.log("Columns :",columns)

      (doc as any).autoTable({ columns, body });//.autoTable((this.exportVisiblesCols.length==0)?this.exportDatasColumns:this.exportVisiblesCols, this.rdatas);
      doc.save("datas.pdf");
    });
    //});
  }

  exportDatasCSV() {
    import("xlsx").then((xlsx) => {
      let columns;
      let body;
      if (this.exportVisiblesCols.length > 0) {
        const expDatas: any[] = [];
        this.rdatas.forEach((elt, elti, eltarr) => {
          let obj: any = {};
          this.exportVisiblesCols.forEach((val, index, arr) => {
            obj[val.title] = elt[val.title]
          });
          console.log("Title exportables:",obj);
          expDatas.push(obj);
        });

        columns = this.exportVisiblesCols;//this.exportDatasColumns;
        body = expDatas;
      } else {
        console.log("Title exportables:",this.exportDatasColumns);
        columns = this.exportDatasColumns;
        body = this.rdatas;
      }

      let Heading = [this.exportDatasColumns.map(x => (x.title))];
      const ws = XLSX.utils.book_new();
      xlsx.utils.sheet_add_aoa(ws, Heading);

      //Starting in the second row to avoid overriding and skipping headers
      const worksheet = xlsx.utils.sheet_add_json(ws, body, { origin: 'A2', skipHeader: true });

      //const worksheet = xlsx.utils.json_to_sheet(body, { header:});
      const csvcontent = xlsx.utils.sheet_to_csv(worksheet,{FS: ';'})

     /* const csvBuffer: any = new ArrayBuffer(csvcontent.length);
      var view = new Uint8Array(csvBuffer);
	    for (var i=0; i!=csvcontent.length; ++i) view[i] = csvcontent.charCodeAt(i) & 0xFF;
   */
      this.saveAsCSVFile(csvcontent, "datas");
    });
  }


  exportDatasExcel() {
    import("xlsx").then((xlsx) => {
      let columns;
      let body;
      if (this.exportVisiblesCols.length > 0) {
        const expDatas: any[] = [];
        this.rdatas.forEach((elt, elti, eltarr) => {
          let obj: any = {};
          this.exportVisiblesCols.forEach((val, index, arr) => {
            obj[val.title] = elt[val.title]
          });
          //console.log("données exportables:",obj);
          expDatas.push(obj);
        });

        columns = this.exportVisiblesCols;//this.exportDatasColumns;
        body = expDatas;
      } else {
        columns = this.exportDatasColumns;
        body = this.rdatas;
      }


       body.map(x => { 
        delete x._id
        return x 
      })
      console.log("body :",body)
      //console.log("newdata :",newdatas)
      
      

      console.log("Entetes :",columns)
      console.log("Datas entete :",Object.keys(body[0]))

      let datasEntetes = Object.keys(body[0]);

      let heading = [];
      datasEntetes.forEach(x => {
        heading.push(columns.filter(z => z.dataKey==x)[0]);
      })
      console.log("Entetes export :",heading)


      /*
      let newdatas = body.map(x => { 
        delete x._id
        return x 
      })
      body = newdatas;

      let newexp = []
      this.exportDatasColumns.forEach((col) => {
        let obj = {
          title: col.title,
          dataKey: col.dataKey
        }
        if (col.title!='Actions') {
          newexp.push(obj)
        }
      })
*/
      //const worksheet = xlsx.utils.json_to_sheet(body);
      //newexp
      let head = this.exportDatasColumns.map(x => (x.title));
      let arrHead = head
      console.log("Entetes :",arrHead);
      console.log("Index =",arrHead.indexOf('Actions'))
      console.log("Arr :",arrHead.splice(arrHead.indexOf('Actions'),1))
      let Heading = [arrHead];

      console.log("Entetes Export :",Heading)

      const ws = XLSX.utils.book_new();
      xlsx.utils.sheet_add_aoa(ws, Heading);

      //Starting in the second row to avoid overriding and skipping headers
      const worksheet = xlsx.utils.sheet_add_json(ws, body, { origin: 'A2', skipHeader: true });

      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      this.saveAsExcelFile(excelBuffer, "datas");
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      FileSaver.saveAs(
        data,
        fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
      );
    });
  }

  saveAsCSVFile(buffer: any, fileName: string): void {
    import("file-saver").then((FileSaver) => {
      let CSV_TYPE =
        "text/plain;charset=UTF-8"; //application/octet-stream
      let CSV_EXTENSION = ".csv";
      const data: Blob = new Blob([buffer], {
        type: CSV_TYPE,
      });
      FileSaver.saveAs(
        data,
        fileName + "_export_" + new Date().getTime() + CSV_EXTENSION
      );
    });
  }






}
