import { Injectable } from '@angular/core';
import { LoadingController, Platform } from '@ionic/angular';
import { Plugins, Capacitor } from '@capacitor/core';

const { PowershellPlugin, CapFeathersPlugin } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class CapService {
  




  isStart: boolean = false;
  feathersPath: string;
  srvEnable: boolean;

  private serveur;
  public pwrshell;
  public port: any;
  projList: any[];
  mainApp: any;
  webServer: any;

  clientSSH: any;
  loading: HTMLIonLoadingElement;


  constructor(private platform: Platform,private loadingController: LoadingController) {
    this.platform.ready().then(() => {
      if (Capacitor.platform === 'electron') {
        this.serveur = CapFeathersPlugin;
        this.pwrshell = PowershellPlugin;
        console.log("Cap Feathers plugin :", this.serveur);
      }


      //this.isStart = CapFeathersPlugin.feathersRef.listening;
    });
  }

  async presentLoading(msg) {
    this.loading = await this.loadingController.create({
      message: msg,
      spinner: 'bubbles'
    });
    await this.loading.present();
  }

  async hideLoading() {
    try {
      await this.loading.dismiss();
    } catch (error) {
      
    }
  }


 restart() {
  this.pwrshell.RemoteRef.app.relaunch();
  this.pwrshell.RemoteRef.app.exit(0);
 } 

 async sshConnect(host,user,password) {
     
    await this.presentLoading('Connexion en cours...')
    this.pwrshell.IpcRenderRef.send('sendBySSH',host,user,password);
    this.pwrshell.IpcRenderRef.on('sshConnected', async (event,res) => {
      console.log("Connection etablie : ",res);
      await this.hideLoading();
    })
    await this.hideLoading();
    await this.presentLoading('Envoi du fichier zipper...')
    this.pwrshell.IpcRenderRef.on('fileZipped', async (event,res) => {
      console.log("Fichier zipper : ",res);
      await this.hideLoading();
    })
    
    this.pwrshell.IpcRenderRef.on('sendBySSH_end', async (event,res) => {
      console.log("Fichier envoyé : ",res);
      await this.hideLoading();
    })
    this.pwrshell.IpcRenderRef.on('sendBySSH_error',(event,res) => {
      console.log("Fichier erreur : ",res);
    })
    //console.log("Client ssh :",this.clientSSH);
  }


  async getFolderList(prjFolder: string): Promise<any> {
    let lstFolder = []
    this.pwrshell.NodeFs.list(prjFolder).forEach(folder => {
      if (this.pwrshell.NodeFs.exists(`${prjFolder}/${folder}`) == 'dir') {
        lstFolder.push(folder);
      }
    });

    return lstFolder
  }

  hasWeb(prjFile:string): any {
    return this.pwrshell.NodeFs.exists(prjFile)
  }

/*
  public init() {
    
    try {
      this.initFeathersJs('./server').then(() => {

       
        console.log('Iniliatisation effectuée');
         this.serveur.getListenPort().then((res) => {
          console.log("Port d'ecoute : ", res);
          this.port = res
          this.checkIsStart().then((res) => {
            console.log("Serveur Interne :", res);
          },(err)=>{
            console.log("Serveur error :", err);
          });
          
        })
        
      },(error)=>{
        console.log(error)
      })
    } catch (error) {
      console.log(error)
    }
  }
*/
  async checkIsStart(): Promise<boolean> {
    if (Capacitor.platform === 'electron') {
      //from(CapFeathersPlugin.isStart()).toPromise().then((res:boolean)=>{
      console.log("Cap Srv Feathers info :", this.serveur);

     /* if (this.serveur.feathersRef && this.serveur.feathersRef.listening) {
        this.isStart = this.serveur.feathersRef.listening
      } else {
        this.isStart = false;
      }*/
      this.isStart = await CapFeathersPlugin.isStart();

      console.log('Srv Interne start check = ', this.isStart);
      return this.isStart;
      //});
    }

  }

  getConfigContent(argPath: string): any {
    return CapFeathersPlugin.NodeFS.read(CapFeathersPlugin.Path.resolve(`${argPath}/config/default.json`), 'utf8');
  }

  getApiUrlContent(): any {
    return CapFeathersPlugin.NodeFS.read(CapFeathersPlugin.Path.resolve(`./assets/config.json`), 'utf8');
  }

  setLocalApiUrl(): void {
    if ( Capacitor.platform == 'electron') {
      CapFeathersPlugin.NodeFS.write(CapFeathersPlugin.Path.resolve(`./assets/config.json`), JSON.stringify({ url: 'http://localhost:3040'},null,2));
      //this.vvservice.apiUrl.next(`http://svrdevweb.agglo.local:3040`);
    }
  }

  getPath(): any {
    return this.serveur.Path.resolve('./')
  }

  initFeathersJs(argPath: string): Promise<any> {
    this.feathersPath = argPath;
    return Promise.resolve(this.serveur.setFeathersPath(this.serveur.Path.resolve(argPath)));
  }

  stopServer(): Promise<any> {
    try {
      return this.serveur.stopServer();
    } catch (error) {
      
    }
    
  }

  startServer(): Promise<any> {
    try {
      return this.serveur.startServer();
    } catch (error) {
      
    }
      
    
    
  }

  async getListenPort() {
    this.port = await CapFeathersPlugin.getListenPort();
    return this.port
    
  }

  async changePort(p: any) {
    //CapFeathersPlugin.stopServer()
    await this.serveur.stopServer();
    this.serveur.changePort(p);
    await this.serveur.startServer();
    this.port = p;

    let vvsrv_url = JSON.parse(this.serveur.NodeFS.read(this.serveur.Path.resolve('./config/default.json'), 'utf8')); //  //config
    vvsrv_url.url = `http://localhost:${p}`;
    this.serveur.NodeFS.write(this.serveur.Path.resolve('./config/default.json'), JSON.stringify(vvsrv_url, null, 2)); // server/public/assets/  config/default.json

    this.restart();
  }


  async setProjetsFolder() {
    await this.serveur.stopServer();
      
      await this.serveur.startServer();
   
    
    //await this.serveur.startServer();
  }


  saveConfigContent(editorContent: any) {
    this.serveur.NodeFS.write(this.serveur.Path.resolve(`${this.feathersPath}/config/default.json`), editorContent);
  }

  showNotification(msg) {
    if (Capacitor.platform == 'electron') {
      console.log("Plugin :", this.pwrshell)
      // informe l'utilisateur par une notification
      this.pwrshell.NotifierRef.notify(
        {
          title: "CACEM : Service DSI",
          message: msg,
          icon: "./assets/appIcon.png",
          appID: "io.dashcacem.app",
          sound: true, // Only Notification Center or Windows Toasters
          wait: false, // Wait with callback, until user action is taken against notification, does not apply to Windows Toasters as they always wait
        },
        function (err, response) {
          // Response is response from notification
        }
      );

      this.pwrshell.NotifierRef.on("click", function (
        notifierObject,
        options,
        event
      ) {
        // Triggers if `wait: true` and user clicks notification
      });
    }
  }

}
