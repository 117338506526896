import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { Plugins, Capacitor } from '@capacitor/core';

import feathers from '@feathersjs/feathers';
import feathersSocketIOClient from '@feathersjs/socketio-client';

import { Storage } from '@ionic/storage';
import { LoadingController } from '@ionic/angular';

import { Observable, throwError, BehaviorSubject, from, Observer, Subscriber } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';

import * as io from 'socket.io-client';

import * as feathersRx from 'feathers-reactive';

import { FileLikeObject } from 'ng2-file-upload';

import { CapService } from './cap.service';


const { PowershellPlugin } = Plugins;




@Injectable({
  providedIn: 'root'
})
export class VvService {

  public feathers: any;

  feathersAuthClient:any; // = require('@feathersjs/authentication-client').default;

  socket: any;

  datas: any;

  chartes: any[] = [];

  apiUrl= new BehaviorSubject<string>(window.location.origin+':3040');
  private _currentUserId: string;
  nbUsers: any;
  nbDisableUsers: any;
  nbNutanix: number;
  nbCitrixDistant: number;
  nbPortables: any;
  nbFixes: number;
  nbCitrix: number;
  nbSrvCitrix: number;
  nbDCs: number;
  titleData: any;
  loading: any;
  svrData: any;
  qryData: any;
  isOnline = new BehaviorSubject<boolean>(false);
  internalSrv = new  BehaviorSubject<boolean>(false);
  currentUser = new BehaviorSubject<any>({});
  currentStategy = new BehaviorSubject<string>('');
  currentPlatForm= new BehaviorSubject<string>('');
  authenticated: boolean;
  activeSegment = new BehaviorSubject<string>('users');
  activesChartes: any[];

  constructor(
    private http: HttpClient,
    private storage: Storage,
    private capservice: CapService,

    //private electronService: typeof PowershellPlugin,
    private loadingController: LoadingController
  ) {


    //url;//`${window.location.origin}`;
    console.log("Chemin : ", window.location.origin);
    console.log("Port : ", window.location.port);
    this.apiUrl.next(window.location.origin);//'http://192.168.8.100:3030'; //
    console.log("API Url avant inititialisation featherjs = ", this.apiUrl.value);





    console.log("Socket creation Url = ", this.apiUrl.value);

    this.feathers = feathers();



    this.getApiUrl().subscribe((data:any) => {

     console.log("Data url :",data)
     if (data.url == "http://localhost:8100") {
      this.apiUrl.next('http://localhost:3040');
     } else {
       this.apiUrl.next(window.location.origin);//'http://chartes.cacem.fr'
     }


       // localhost:3030   ds assets/config.json seulement en compilation

	  this.socket = require('socket.io-client')(window.location.origin, { transports: ['websocket'] ,  reconnection: true, rejectUnauthorized: false });
      console.log('Chemin du serveur defini dans la conf :',this.apiUrl.value);

      this.feathersAuthClient = require('@feathersjs/authentication-client').default;


      this.feathers.configure(
        feathersRx({
          // add feathers-reactive plugin
          idField: "_id",
        })
      );

      this.feathers.configure(
        feathersSocketIOClient(this.socket, { timeout: 10000 })
      );//feathersSocketIOClient



      this.feathers.configure(this.feathersAuthClient({                   // add authentication plugin
        storage: window.localStorage
      }));



      console.log("Feather Client service :", this.feathers);

      console.log('Le serveur interne est démarré');


      this.feathers.io.on('connect', async () => {
        console.log("Socket connect ");
        console.log('Chemin actuel du socket client :',this.feathers.io.io.uri);
        console.log("Feathersjs client :",this.feathers);
        console.log("Feathersjs client id :",this.feathers.io.id);
        console.log("Feathersjs clients ids :",this.feathers.io.ids);
        this.authenticated = this.feathers.authentication.authenticated;
        this.currentUser.next(await this.feathers.get('authentication'))

        if ((String(this.feathers.io.io.uri).lastIndexOf('localhost') != -1) || (String(this.feathers.io.io.uri).lastIndexOf('127.0.0.1') != -1)) {
          this.internalSrv.next(true);
          console.log("Serveur interne ",this.feathers.authentication.authenticated)
        } else {
          this.internalSrv.next(false);
        }
        this.isOnline.next(true);



      });

      this.feathers.io.on('connect_error', async (err) => {
        console.log("new Socket connect error ",err);
        this.isOnline.next(false);
      });


    });


    console.log("Client Feathersjs service :", this.feathers);
    this.feathers.on('error',(error)=>{
      console.log("Error :",error)
    })



  }

  async presentLoading() {
    this.loading = await this.loadingController.create({
      message: 'Chargement en cours...',
      //duration: 20000,
      spinner: 'bubbles'
    });
    this.loading.present();
  }

  /*getApiUrl(): Observable<any> {


      return new Observable<any>((obj:Subscriber<any>)=>{
        obj.next({ url: window.location.origin});
        console.log("Url web conf :",obj);

      });


  }*/

  // expose authentication
  async authenticate(credentials?): Promise<any> {
    return new Promise(async (resolve,reject)=> {
      if (this.feathers) {

       console.log('client :',this.feathers);
        console.log('Chemin actuel du socket client :',this.feathers.io.io.uri);

        if (credentials?.username) {
          console.log('Credential :',credentials);
          try {
            var authResult:any = await this.feathers.authenticate(credentials);
            console.log("Valeur cred : ",authResult);
          } catch (error) {
            return reject(false)
          }

        } else {
          let test = this.feathers.authentication.storage.storage['feathers-jwt'];//await this.storage.get('feathers-jwt');
          console.log("Storage :",test)
          if (this.feathers.authentication.authenticated) {
            try {
              var authResult:any = await this.feathers.authentication.reAuthenticate();
            } catch (error) {
              return reject(false)
            }
            //this.feathers.get('authentication');//this.storage.get('feathers-jwt');
            if (authResult?.accessToken) {
              console.log("Token :",authResult?.accessToken)

            } else {
              this.currentUser.next({});
              this.authenticated = this.feathers.authentication.authenticated;
              return reject(false)
            }
          } else {
            this.currentUser.next({});
            this.authenticated = this.feathers.authentication.authenticated;
            return reject(false)
          }

          console.log("Auth test :",this.feathers.authentication.authenticated)


        }


          this.currentStategy.next(authResult.authentication.strategy);

          var user = JSON.parse(JSON.stringify(authResult.user));
          if (!user) {
            user = JSON.parse(JSON.stringify(authResult.user.user));
          }
          console.log("User :",user);
          if (user?.memberOf) {
              console.log("Groupes :", user.memberOf);
              let memberOf = user.memberOf
              if (!user?.memberOf) {
                console.log("Groupes reel :", user?.user?.memberOf);
                memberOf = user?.user?.memberOf;
              }
              console.log("Groupe trans :", JSON.parse(JSON.stringify(memberOf)));
              var token = authResult.accessToken;

                //user = authResult.user.user;
                var grpmembers = ((typeof memberOf)=='string')?[memberOf]:memberOf;

              console.log("Auth membres :",grpmembers);


                if ( grpmembers ) {

                    let grpes = [];

                    //console.log("Membres :",grpmembers)
                  user.memberOf = grpmembers.map( x => String(x).replace(/CN=/g,'').replace(/cn=/g,'').split(",")[0] );
                  //console.log("Membres :",user.memberOf)
                  grpes = user.memberOf

                  let admGrpe = this.feathers.get('admGroupe');

                  if ( grpes.lastIndexOf((admGrpe)?admGrpe:'Utilisateurs DSI') != -1 ) {
                    user.role = 'admin';
                  } else {
                    if (user.role == null) {
                      user.role = 'invite';
                    }

                  }
                }

              this.authenticated = true
              this.currentUser.next({ token: token, role: user.role , email: user.mail, gravatar: null, user: user });
          }
          resolve(this.currentUser.getValue());


      } else {
        this.authenticated = false;
        return reject(false)
      }
    })


  }


  // expose logout
  public async logout() {
    return await this.feathers.logout();
  }




  posusers$() {
    return (this.feathers
      .service('/posusers'))
      .watch()
      .find()
  }

  addPosUser(username, carte, posx, posy) {
    this.feathers.service('/posusers')
      .create({
        userId: username,
        etage: carte,
        cx: Number(posx),
        cy: Number(posy)
      })
  }

  tables$() {
    return (<any>this.feathers.service('/databases'))
    .watch().find({})
  }

  getSelTables$(dbName,dbType) {
    return (<any>this.feathers.service('/databases'))
    .watch().find({
      query: {
        dbName: dbName,
        dbType: dbType
      }
    })
  }

  getForm$(query) {
    return (<any>this.feathers.service('/forms')
    .watch()).find({
      query: query
    })
  }


  messages$() {
    // just returning the observable will query the backend on every subscription
    // using some caching mechanism would be wise in more complex applications
    return (<any>this.feathers
      .service('/messages'))
      .watch()
      .find({
        query: {
          $sort: { createdAt: -1 },
          $limit: 25
        }
      });
  }


  messagesUser$(tuname, funame) {
    // just returning the observable will query the backend on every subscription
    // using some caching mechanism would be wise in more complex applications
    return (<any>this.feathers
      .service('/messages'))
      .watch()
      .find({
        query: {
          $sort: { createdAt: -1 },
          toUser: tuname,
          userId: funame,
          $limit: 25
        }
      });
  }

  users$() {
    // just returning the observable will query the backend on every subscription
    // using some caching mechanism would be wise in more complex applications
    return (<any>this.feathers
      .service('/usersInfos'))
      .watch()
      .find();
  }



  sendMessage(message: string, username: string, room: string) {
    if (message === '') {
      return;
    }

    // feathers-reactive Observables are hot by default,
    // so we don't need to subscribe to make create() happen.
    this.feathers
      .service('/messages')
      .create({
        text: message,
        toUser: username,
        roomId: room
      });
  }

  getRocketToken(): Observable<any> {
    return this.http.post<any>(`${this.apiUrl.value}/api/v1/rocketchat/login`, null, this.httOptionNonSecure).pipe(
      retry(2),
      catchError(this.handleError));

  }



  getDatas$(serviceName: string, qry: any) {
    //return await this.feathers.service(serviceName).find(qry)
    return  (this.feathers.service(serviceName)).watch().find(qry)

  }















  async getTotalDisableUsers(): Promise<any> {
    return this.feathers.service('adUsers').find({
      query: {
        Enabled: false
      }
    })
    /*this.feathers.service('adUsers').find({
      query: {
        Enabled: false
      }
    }).then((users)=>{
      console.log('Utilisateurs inactif:',users);
      this.nbDisableUsers = Object(users).length;
    }).catch((err) => {
      console.log("Données ordinateurs impossibles")
    })*/
  }

  async getTotalMemberUsers(value): Promise<any> {
    return this.feathers.service('adUsers').find({
      query: {
        MemberOf: {
          $in: [value]
        }
      }
    })

  }




  getCurrentUserId(): string {
    return this._currentUserId;
  }

  setCurrentUserId(loginUser: string) {
    this._currentUserId = loginUser;
  }

  getApiUrl(): Observable<any> {


    /*return this.http.get<Settings>(url, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }), params: { responseType: 'text' as "json" }
    })*/
   /* if (Capacitor.platform == 'electron') {

      //recupération de l'url du fichier ${appPath}/assets/config.json
      return new Observable<any>((obj:Subscriber<any>)=>{
        obj.next(JSON.parse(this.capservice.getApiUrlContent()));
        console.log("Url app conf :",obj);
        //recupération de l'url du fichier ${appPath}/assets/config.json
        this.apiUrl.next(JSON.parse(this.capservice.getApiUrlContent()).url);
        // défini que nous sommes dans l'application exécutable le serveur interne
        this.internalSrv.next(true)



      }
      );
    } else {*/
      return new Observable<any>((obj:Subscriber<any>)=>{

        obj.next({ url: window.location.origin});//http://chartes.cacem.fr:3040
        console.log("Url web conf :",obj);

      });
      /*
      return this.http.get<any>(`assets/config.json`).pipe(
      retry(2),
      catchError(this.handleError)
      );*/
    //}

  }



 getSvrSettings() {
  return this.http.get<any>(`${this.apiUrl.value}/api/svrsettings`).pipe(
    retry(2),
    catchError(this.handleError));
 }

 getProjets() {
   return this.http.get<any>(`${this.apiUrl.value}/projects`).pipe(
    retry(2),
    catchError(this.handleError));

 }

 getExchangeStatus() {
  return this.http.post<any>(`${this.apiUrl.value}/api/execexg`,JSON.stringify({ cmd: "Get-MailboxDatabase -Status "}),this.httpOptions).pipe(
    retry(2),
    catchError(this.handleError));
 }


 sendCharteConfirm(email,sujet,content) {

  return this.http.post<any>(`${this.apiUrl.value}/api/chartemail`,JSON.stringify({ sendTo: email, sujet: sujet, htmlcontent: content}),this.httpCorsOptions).pipe(
    retry(2),
    catchError(this.handleError));
 }

  arrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  // Http Options
  httOptionNonSecure = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };


  httpCorsOptions = {
    headers: new HttpHeaders({
      
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Request-Method":"method",
      "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE, OPTIONS",
      "Access-Control-Allow-Headers":"Origin, X-Requested-With, Content-Type, Accept, Referer, User-Agent, Auth-Token",
      'Content-Type': 'application/json',
      "Accept": "application/json",
    })
  }

  httpSimplyOptions = {
    headers: new HttpHeaders({
      "Authorization": "Basic " + btoa("hdechavigny:d@nZel!77"),
      //"Authorization-ApiKey": "9mBvxZhKSQBMaRUxuq4ndIMori1HsLcY+IlgT61VLMU=",
      "contentType": "application/json; charset=utf-8",
      "Accept": "application/json",
      "Allow": "*",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "POST, GET, PUT, DELETE, OPTIONS",
      "Access-Control-Allow-Headers":"Origin, X-Requested-With, Content-Type, Accept, Referer, User-Agent, Auth-Token"
    }

    )
  }

  httpFilesOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'multipart/form-data'
    })
  };

  /*
    httOptionSecure = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+this.authService.token.value
        })
    };

    httpOptions = this.authService.token.value?this.httOptionSecure:this.httOptionNonSecure;
  */

  httpOptions = this.httOptionNonSecure;




  /*

   modif: (new Date(Number(String(Object(res).whenChanged).match(/\d/g).join("")))).toLocaleString(),
              creation: (new Date(Number(String(Object(res).whenCreated).match(/\d/g).join("")))).toLocaleString(),
              lastlog: (new Date(Number(String(Object(res).LastLogonDate).match(/\d/g).join("")))).toLocaleString(),
              picture: (Object(res).ThumbnailPhoto == null) ? "" : "data:image/jpeg;base64," + this.arrayBufferToBase64(Object(res).ThumbnailPhoto).toString(),

  */

  // Handle API errors
  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('Il y a une erreur :', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend code retour  ${error.status}, ` +
        `body : ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Il y a un problème; svp essayer plus tard.');
  }






  getUserInfos(userid): Observable<any> {
    return this.http.get<any>(`${this.apiUrl.value}/adUsers?SamAccountName=${userid}`).pipe(
      retry(2),
      catchError(this.handleError)
    )

  }


  getSimplyUsers(): Observable<any> {
    //return this.http.get<any>(`http://svrsimplydesk.agglo.local:4000/CustomerManagement.svc/GetUsers?p=1&datemin=2020-01-01&datemax=2020-10-01`, this.httpSimplyOptions).pipe(
    return this.http.post<any>(`${this.apiUrl.value}/getSUsers`,null,this.httpOptions).pipe(
      retry(2),
      catchError(this.handleError)
    )
  }

  //getSAllTeam
  getSimplyTeam(): Observable<any> {
    //return this.http.get<any>(`http://svrsimplydesk.agglo.local:4000/CustomerManagement.svc/GetUsers?p=1&datemin=2020-01-01&datemax=2020-10-01`, this.httpSimplyOptions).pipe(
    return this.http.post<any>(`${this.apiUrl.value}/getSAllTeam`,null,this.httpOptions).pipe(
      retry(2),
      catchError(this.handleError)
    )
  }
  ///getSAgentsByteamId

  getSimplyAgentsByteamId(idteam): Observable<any> {
    console.log("Id :",idteam)
    //return this.http.get<any>(`http://svrsimplydesk.agglo.local:4000/CustomerManagement.svc/GetUsers?p=1&datemin=2020-01-01&datemax=2020-10-01`, this.httpSimplyOptions).pipe(
    return this.http.post<any>(`${this.apiUrl.value}/getSAgentsByteamId`,JSON.stringify({ id: idteam}),this.httpOptions).pipe(
      retry(2),
      catchError(this.handleError)
    )
  }

  //getSAgents
  getSimplyAgents(): Observable<any> {
    //return this.http.get<any>(`http://svrsimplydesk.agglo.local:4000/CustomerManagement.svc/GetUsers?p=1&datemin=2020-01-01&datemax=2020-10-01`, this.httpSimplyOptions).pipe(
    return this.http.post<any>(`${this.apiUrl.value}/getSAgents`,null,this.httpOptions).pipe(
      retry(2),
      catchError(this.handleError)
    )
  }
//IncidentManagement.svc/GetAllTicketCategories
  getSimplyCategories(): Observable<any> {
    //return this.http.get<any>(`http://svrsimplydesk.agglo.local:4000/CustomerManagement.svc/GetUsers?p=1&datemin=2020-01-01&datemax=2020-10-01`, this.httpSimplyOptions).pipe(
    return this.http.post<any>(`${this.apiUrl.value}/getSCategories`,null,this.httpOptions).pipe(
      retry(2),
      catchError(this.handleError)
    )
  }
//IncidentManagement.svc/GetAllPriorities
  getSimplyAllPriorities(): Observable<any> {
    //return this.http.get<any>(`http://svrsimplydesk.agglo.local:4000/CustomerManagement.svc/GetUsers?p=1&datemin=2020-01-01&datemax=2020-10-01`, this.httpSimplyOptions).pipe(
    return this.http.get<any>(`${this.apiUrl.value}/getSAllPriorities`,this.httpOptions).pipe(
      retry(2),
      catchError(this.handleError)
    )
  }
//IncidentManagement.svc/GetAllTicketTypes
  getSimplyAllTicketTypes(): Observable<any> {
    //return this.http.get<any>(`http://svrsimplydesk.agglo.local:4000/CustomerManagement.svc/GetUsers?p=1&datemin=2020-01-01&datemax=2020-10-01`, this.httpSimplyOptions).pipe(
    return this.http.post<any>(`${this.apiUrl.value}/getSAllTicketTypes`,null,this.httpOptions).pipe(
      retry(2),
      catchError(this.handleError)
    )
  }

//IncidentManagement.svc/GetLinkedTicketCatgoriesByTicketTypeID?ticketTypeId=5f4cba96-6438-4afe-89a9-10b566125b30  type Incidents
//getSAllTicketIncidents
  getSimplyAllTicketIncidents(): Observable<any> {
    //return this.http.get<any>(`http://svrsimplydesk.agglo.local:4000/CustomerManagement.svc/GetUsers?p=1&datemin=2020-01-01&datemax=2020-10-01`, this.httpSimplyOptions).pipe(
    return this.http.post<any>(`${this.apiUrl.value}/getSAllTicketIncidents`,null,this.httpOptions).pipe(
      retry(2),
      catchError(this.handleError)
    )
  }


  newIncidentTicketAdm(ticket): Observable<any> {
    return this.http.post<any>(`${this.apiUrl.value}/addSTicketAdm`,JSON.stringify(ticket),this.httpOptions).pipe(
      retry(2),
      catchError(this.handleError)
    )
  }

  newIncidentTicket(ticket): Observable<any> {
    return this.http.post<any>(`${this.apiUrl.value}/addSTicket`,JSON.stringify(ticket),this.httpOptions).pipe(
      retry(2),
      catchError(this.handleError)
    )
  }




  runExchange(cmd): Observable<any> {
    return this.http.post<any>(`${this.apiUrl.value}/api/v1/exchg/cmd`, JSON.stringify({ cmd: `${cmd}` }), this.httpOptions).pipe(
      retry(2),
      catchError(this.handleError));
  }

  dynService_find(dbType, dbName, query): Promise<any> {
    return this.feathers.service('tables').find({
      query: {
        tableDb: dbType,//'nedb','mongodb'
        tableName: dbName,
        query: query
      }
    })
  }

  obsService_find$(dbType, dbName, query) {
    return (<any>this.feathers.service('/tables')).watch().find({
      query: {
        tableDb: dbType,
        tableName: dbName,
        query: query
      }
    })
  }


  dynService_remove(id, dbType, dbName, params): Promise<any> {
    return this.feathers.service('tables').remove(id, {
      query: {
        tableDb: dbType,//'nedb','mongodb'
        tableName: dbName,
        params: params
      }
    })
  }




  dynService_update(id, data, dbType, dbName,params): Promise<any> {
    return this.feathers.service('tables').update(id, data, {
      query: {
        tableDb: dbType,//'nedb','mongodb'
        tableName: dbName,
        params: params
      }
    })
  }



  dynService_create(dbType, dbName, data): Promise<any> {
    return this.feathers.service('tables').create(data, {
      query: {
        tableDb: dbType,//'nedb','mongodb'
        tableName: dbName
      }
    })
  }







  uploadFile(files: FileLikeObject[]): Observable<any> {
    var formData: FormData = new FormData();
    //formData.append('somekey', 'some value') // Add any other data you want to send

    files.forEach((file) => {
      formData.append('files[]', file.rawFile, file.name);
    });
    return this.http.post<any>(`${this.apiUrl.value}/uploadmultiple`, formData).pipe(
      retry(2),
      catchError(this.handleError));
  }






}
